<template>
    <div>
        <h2>{{ $t("compta.pensions") }}</h2>
        <div class="box">
            <CustomTable
                id_table="pension_type"
                :items="filtredPension"
                :busy.sync="table_busy"
                primaryKey="pension_id"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
                :externSlotColumns="['pension_inactive', 'pension_invoiceable']"
            >
                <template v-slot:[`custom-slot-cell(pension_inactive)`]="{ data }">
					<div class="custom-control custom-checkbox">
						<b-form-checkbox 
							name="check-button"
                            switch 
							v-model="data.switch" 
							@change="switchPension(data.pension_id, data.switch)"
						>
							<template v-if="data.switch">
								{{ $t('pension.enable') }}
							</template>
							<template v-else>
								{{ $t('pension.disable') }}
							</template>
						</b-form-checkbox>
					</div>
				</template>
                <template v-slot:[`custom-slot-cell(pension_invoiceable)`]="{ data }">
                    <span> {{ sumOfAllArticleInvoiceableIn( data.article ) }} / {{ sumOfAllArticleInvoiceableIn( data.article, false ) }} </span>
                </template>
            </CustomTable>

            <b-modal ref="modal" hide-footer>
                <template v-slot:modal-title>
                    <template v-if="ajouter">
                        {{ $t("compta.ajouter_pension_type") }}
                    </template>

                    <template v-if="modifier">
                        {{ $t("compta.modifier_pension_type") }}
                    </template>
                </template>

                <PensionAjout 
                    :add-or-edit-ready.sync="addOrEditReady"
                    ref="pensionAjout"
                    :pension_id="pension_id"
                    :accountingplan_id="accountingplan_id"
                    :pension="pension"
                />
                <div class="text-center">
                    <b-button v-if="ajouter && addOrEditReady"  pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
                    
                    <div v-if="modifier && addOrEditReady">
                        <b-button pill variant="primary" class="mt-3"  @click.prevent="checkForm('without_update')"><font-awesome-icon v-if="processing_noupdate" :icon="['fas', 'spinner']" pulse /> {{ $t("modal.pension.no_update_tarifs") }}</b-button>
                        <b-button pill variant="primary" class="mt-3"  @click.prevent="checkForm('with_update')"><font-awesome-icon v-if="processing_update" :icon="['fas', 'spinner']" pulse /> {{ $t("modal.pension.update_tarifs") }}</b-button>
                        <b-button pill variant="primary" class="mt-3"  @click.prevent="checkForm(null)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("modal.pension.modify_config") }}</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script type="text/javascript">
	import Common from '@/assets/js/common'
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js";
    import Accounting from "@/mixins/Accounting.js"
    import Shutter from '@/mixins/Shutter.js'

    export default {
        name: "pension",
        mixins: [TableAction, Navigation, Accounting, Shutter],
        props: ['accountingplan_id', 'code_onboarding'],
        data () {
            return {
                addOrEditReady: false,
                processing: false,
                processing_noupdate: false,
                processing_update: false,
                ajouter: false,
                modifier: false,
                pension_id: null,

                pension: null,
                table_busy: true,
                rawColumnNames: ['pension_articles', 'pension_ht', 'pension_vat', 'pension_accountingaccount', 'pension_monthly_html'],
                events_tab: {
                    'TableAction::goToAddPensionType': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditPensionType': (params) => {
                        this.addOrEdit(params.pension_id)
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadPensionComponent()
            },
            async loadPensionComponent() {
                this.table_busy = true
                let pensions = await this.loadPensionType(this.accountingplan_id)
                if(pensions) {
                    let temp = []
					await Common.asyncForEach(
						pensions,
						async (pension) => {

							if(pension.pension_invoiceable_in && pension.pension_invoiceable_out) {
								pension.pension_invoiceable = this.getTrad("compta.es")
							} else if(pension.pension_invoiceable_out) {
								pension.pension_invoiceable = this.getTrad("compta.s")
							} else if(pension.pension_invoiceable_in) {
								pension.pension_invoiceable = this.getTrad("compta.e")
							}

							pension.pension_articles = ''
							pension.pension_ht = ''
							pension.pension_vat = ''
							pension.pension_accountingaccount = ''
                            pension.pension_monthly_html = ''

							if(pension.article) {
                                let pension_ht = []
								await Common.asyncForEach(
									pension.article,
									async (article) => {
                                        pension.pension_monthly = article.pensionarticle_monthly
										pension.pension_articles = pension.pension_articles + article.articles_label + "<br>"
										pension_ht.push(await this.priceFormat(article.articles_ht, pension.tiers_currency, true))
										pension.pension_vat = pension.pension_vat + article.vat.vat_label + "<br>"
										pension.pension_accountingaccount = pension.pension_accountingaccount + article.accounting_account.accountingaccount_number + "<br>"
                                        if(pension.pension_monthly) {
                                            pension.pension_monthly_html = pension.pension_monthly_html + this.getTrad("compta.mensuel") + "<br>"
                                        } else {
                                            pension.pension_monthly_html = pension.pension_monthly_html + this.getTrad("compta.journalier") + "<br>"
                                        }
									}
								)
                                pension.pension_ht = pension_ht.join('<br>')
							}


                            pension.switch = !pension.pension_inactive

							temp.push(pension)
						}
					)

                    this.pension = temp
                } else {
                    this.pension = null
                }

                this.table_busy = false
            },
            async addOrEdit(pension_id) {
                this.processing = false
                this.processing_noupdate = false
                this.processing_update = false
                this.addOrEditReady = false
                if(pension_id) {
                    this.ajouter = false
                    this.modifier = true
                    this.pension_id = pension_id
                } else {
                    this.ajouter = true
                    this.modifier = false
                    this.pension_id = null
                }

                this.$refs.modal.show()
            },
            async checkForm(tarif = null) {
                if(!this.processing && !this.processing_noupdate && !this.processing_update) {
                    if(tarif === 'without_update') {
                        this.processing_noupdate = true
                    }
                    else if(tarif === 'with_update') {
                        this.processing_update = true
                    }
                    else {
                        this.processing = true
                    }

                    let result = await this.$refs.pensionAjout.checkForm(tarif)
                    if(result) {
                        this.$refs.modal.hide()
                        if(this.code_onboarding) {
                            this.ok()
                        }
                        else {
                            this.loadPensionComponent()
                        }
                    } else {
                        this.processing = false
                        this.processing_noupdate = false
                        this.processing_update = false
                    }
                }
            },
            switchPension(pension_id, state) {
				this.changePensionState(pension_id, state)
			},
            sumOfAllArticleInvoiceableIn(articleToIterate, invoiceableIn = true) {
                let sum = 0
                articleToIterate.forEach(element => {
                    invoiceableIn ?
                    sum += element.pensionarticle_invoiceable_in :
                    sum += element.pensionarticle_invoiceable_out
                });
                return sum
            }
        },
        computed: {
            filtredPension: function() {
                return this.pension
            }
        },
        components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            HeaderTab : () => import('@/components/HeaderTab'),
            PensionAjout : () => import('@/components/Accounting/PensionAjout'),
        }
    }
</script>
